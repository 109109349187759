import { branch } from 'baobab-react/higher-order';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import ContractActions from '../../actions/Contract';
import ProfileActions from '../../actions/Profile';
import ChooseDocumentModal from '../../components/Modals/ChooseDocumentModal';
import GenericConfirmModal from '../../components/Modals/GenericConfirmModal';
import { cleanText } from '../../utils/cleanText';
import { emailRegex } from '../../utils/constants';
import { usersMostUsedNewContract } from '../../utils/newContractMassiveUtils';
import ChooseSignatureType from './ChooseSignatureType';
import ConfigSigners from './ConfigSigners';
import InfoSignersSife from './InfoSignersSife';
import SignersListNewContract from './SignersListNewContract';
import { Tooltip } from './Tooltip';

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

class SignersDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.debouncedOnKeyUpGenerateSoraId = debounce(
      this.onKeyUpSearchByEmail.bind(this),
      500,
    );
    this.state = {
      showTableSigners: true,
      dropdownSigners: false,
      activeButtonUploadPdf: false,
      signTypeSigner: '',
      showMessageSigner: false,
      orderedSigners: true,
      allSigners: [],
      searchSigner: '',
      lastSigners: [],
      signers: [],
      contractUser: '',
      dropdown: [
        'Indistinta',
        'e.firma (SAT)',
        'Firma electrónica criptográfica (con RFC)',
        'Firma electrónica criptográfica (Extranjero)',
      ],
      documentModal: false,
      signerDocument: null,
      modalDocumentType: '',
      allDocuments: [],
      documentsList: [
        'INE-IFE',
        'CURP',
        'Acta Constitutiva',
        'Poder Notarial',
        // 'Otro',
      ],
      showIdentityDocs: false,
      multipleCheckboxSife: {
        globalVerifyCreateSign: true,
        globalRequestVerification: false,
      },
      showGlobalDisclaimer: false,
      showTooltipReqVer: false,
      showTooltipReqCreateSign: false,
      modalWithOutRfc: { open: false, signerId: null },
    };
  }

  componentDidMount = async () => {
    const { session, signer, admin, updateStateNewContract } = this.props;
    const { user } = session;
    const contractUser = signer || admin || user;
    const id = contractUser.rootUser || contractUser.id;
    const signersArr = await ProfileActions.getLastSigners({
      userId: id,
      typeUser: 'signer',
      isAdmin: signer || admin ? 'isAdmin' : 'isUser',
    });
    const response = await ContractActions.getUserSife(contractUser.legalRfc);
    const newSigner = {
      legalName: contractUser.legalName,
      legalRfc: contractUser.legalRfc,
      email: contractUser.secondaryEmail || contractUser.email,
      requestDocument: new Set(),
      requestVerification: false,
      verifyCreateSign: response.allVerified,
      hideButtonCreateSign: response.allVerified,
    };
    this.setState({ lastSigners: signersArr, allSigners: signersArr });
    this.setState({ contractUser });
    this.setState({ signers: [newSigner] });
    updateStateNewContract({ signers: [newSigner] });
    document.addEventListener('mousedown', this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { searchSigner, lastSigners } = this.state;
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      searchSigner &&
      lastSigners.length
    ) {
      this.setState({ lastSigners: [] });
    }
  };

  handleTableSigners = () => {
    const { showTableSigners } = this.state;
    this.setState({ showTableSigners: !showTableSigners });
  };

  handleChangeTypeSign = (e) => {
    const signType = e.target.value;
    const { dropdownSigners, signers } = this.state;
    const { updateStateNewContract } = this.props;

    // Opcion 1: Firma indistinta
    // Opcion 2: e firma
    // Opcion 3: sife
    // Opcion 4: multiples firmas ---> despliega el menu de firma en la tabla

    if (signType !== '4') {
      this.setState({ signTypeSigner: signType });
      this.setState({ dropdownSigners: false });
      this.setState({ showMessageSigner: false });
      updateStateNewContract({ signTypeSigner: signType });
    }

    if (signType === '2' || signType === '3') {
      this.setState({ showMessageSigner: true });
      this.setState({ dropdownSigners: false });
    }

    if (e.target.value === '4') {
      this.setState({ dropdownSigners: !dropdownSigners });
      this.setState({ showMessageSigner: false });
      this.setState({ signTypeSigner: '' });
      updateStateNewContract({ signTypeSigner: '' });
    }

    //SHOW CHECKBOX TO SIGNERS WHEN CHANGE GENERAL SIGN
    const allSigners = signers.map((signer) => {
      return {
        ...signer,
        showButtons:
          signType === '3' ||
          (signType === '4' &&
            (signer.signType === '3' || signer.signType === '4'))
            ? true
            : false,
      };
    });
    this.setState({ signers: allSigners });
    updateStateNewContract({
      activeButtonUploadPdf: signType === '4' ? true : false,
      signers: allSigners,
    });
  };

  handleChangeOrderSigners = (orderedSigners) => {
    const { updateStateNewContract } = this.props;
    this.setState({ orderedSigners: !orderedSigners });
    updateStateNewContract({ orderedSigners: !orderedSigners });
  };

  onChangeSearchSigner = (event) => {
    const { allSigners } = this.state;
    const value = event.target.value;
    const lastChar = value[value.length - 1];
    if (lastChar === '\\' || lastChar === '?') return;
    this.setState({ searchSigner: value });
    const limitedSigners = usersMostUsedNewContract(value, allSigners);
    this.setState({ lastSigners: limitedSigners });
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  onClickSelectSigner = async (signer) => {
    const { signers, allDocuments, multipleCheckboxSife, signTypeSigner } =
      this.state;
    const { updateStateNewContract } = this.props;
    const response = await ContractActions.getUserSife(signer.legalRfc);
    signer.requestDocument = new Set(allDocuments);
    signer.verifyCreateSign = response.allVerified;
    signer.hideButtonCreateSign = response.allVerified;
    signer.showButtons = signTypeSigner === '3' ? true : false;
    signer.acceptedDisclaimer = !multipleCheckboxSife.globalVerifyCreateSign
      ? true
      : false;
    this.setState({
      signers: [...signers, signer],
      searchSigner: '',
      lastSigners: [],
    });
    updateStateNewContract({ signers: [...signers, signer] });
  };

  signerInputChange = async (signerId, event) => {
    const { name, value } = event.target;
    const { signers, contractUser } = this.state;
    const { updateStateNewContract, translations, lang } = this.props;

    const { notifications } = translations[lang].NewContract.Signers;

    const allSigners = [...signers];

    if (name === 'signType' && value === '4') {
      return this.setState({
        modalWithOutRfc: { open: true, signerId },
      });
    }

    if (name === 'legalRfc' || name === 'email') {
      allSigners[signerId][name] = cleanText({
        removeSpace: true,
        string: value,
      });
    } else {
      allSigners[signerId][name] = cleanText({
        removeSpace: false,
        string: value,
      });
    }

    if (name === 'signType' && value === '3') {
      allSigners[signerId].showButtons = true;
    }

    //Si ya se habia llenado el campo de rfc y lo cambian a multiple firma
    //y seleccionan firma sin rfc, se limpia rfc anterior si es que existía
    // y ocultamos ese campo de rfc
    if (name === 'signType' && value === '4') {
      const email = allSigners[signerId].email;
      if (email && email.match(emailRegex)) {
        await this.generateIdSora(email, signerId);
        allSigners[signerId].disableLegalRfc = false;
      } else {
        allSigners[signerId].legalRfc = '';
        allSigners[signerId].disableLegalRfc = true;
      }
      allSigners[signerId].showButtons = true;
    }

    this.setState({ signers: allSigners });
    updateStateNewContract({ signers: allSigners });

    if (name === 'legalRfc') {
      const myDuplicates = signers.filter(
        (signer) => signer.legalRfc.search(contractUser.legalRfc) !== -1,
      );
      if (myDuplicates.length > 1) {
        NotificationManager.warning(
          notifications.notifBody1,
          notifications.notifTitle1,
          3000,
        );
      }
    }
  };

  closeModalWithOutRfc = async (type) => {
    const { signers, modalWithOutRfc } = this.state;
    const { updateStateNewContract } = this.props;
    const { signerId } = modalWithOutRfc;
    const allSigners = [...signers];
    allSigners[signerId]['signType'] = type === 'accept' ? '4' : '';
    const email = allSigners[signerId].email;
    if (email && email.match(emailRegex)) {
      await this.generateIdSora(email, signerId);
      allSigners[signerId].disableLegalRfc = false;
    } else {
      allSigners[signerId].legalRfc = '';
      allSigners[signerId].disableLegalRfc = true;
    }
    allSigners[signerId].showButtons = true;
    this.setState({ signers: allSigners });
    updateStateNewContract({ signers: allSigners });
    this.setState({ modalWithOutRfc: { open: false, signerId: null } });
  };

  //ONLY CHECKBOX
  signerCheckboxChange = (signerId, event, document) => {
    const { name, checked } = event.target;
    const { signers } = this.state;
    const { updateStateNewContract } = this.props;
    const allSigners = [...signers];
    //REQUEST DOCUMENTS TO SIGNER
    if (name === 'requestDocument') {
      checked
        ? allSigners[signerId][name].add(document)
        : allSigners[signerId][name].delete(document);
    }

    //REQUEST VERIFICATION
    if (name === 'requestVerification') {
      allSigners[signerId][name] = checked;
    }

    //SHOW MODAL DISCLAIMER TO SIGNER
    if (name === 'verifyCreateSign') {
      allSigners[signerId].showModal = !checked ? true : false;
    }

    //CHECK INPUT IF USER ACCEPT DISCLAIMER
    if (name === 'verifyCreateSign' && checked) {
      allSigners[signerId][name] = checked;
    }

    //SAVE SIGNERS
    this.setState({ signers: allSigners });
    updateStateNewContract({ signers: allSigners });
  };

  removeSigner = (e) => {
    const { signers } = this.state;
    const { updateStateNewContract } = this.props;
    const array = [...signers];
    const filteredArray = array.filter((signer) => signer !== e);
    this.setState({ signers: filteredArray });
    updateStateNewContract({ signers: filteredArray });
  };

  onClickOpenDocumentModal = (signerDocument, index, type) => {
    const { documentModal } = this.state;
    if (type !== 'allSigners') {
      signerDocument.id = index;
    }
    return this.setState({
      documentModal: !documentModal,
      signerDocument,
      modalDocumentType: type,
    });
  };

  addOrDeleteDocsSigner = (action, doc, signerId) => {
    let { allDocuments, signers, modalDocumentType } = this.state;
    const { updateStateNewContract } = this.props;
    const allSigners = [...signers];
    if (action === 'add' && modalDocumentType === 'allSigners') {
      allDocuments.push(doc);
    }

    if (modalDocumentType === 'allSigners') {
      const filteredDocuments =
        action === 'add'
          ? [...new Set(allDocuments)]
          : allDocuments.filter((document) => document !== doc);
      const allSignersUpdated = allSigners.map((signer) => {
        const signerUpdated = { ...signer };
        action === 'delete'
          ? signerUpdated.requestDocument.delete(doc)
          : signerUpdated.requestDocument.add(doc);
        return signerUpdated;
      });
      updateStateNewContract({ signers: allSignersUpdated });
      return this.setState({
        signers: allSignersUpdated,
        allDocuments: filteredDocuments,
      });
    }

    action === 'delete'
      ? allSigners[signerId]['requestDocument'].delete(doc)
      : allSigners[signerId]['requestDocument'].add(doc);
    this.setState({ signers: allSigners });
    updateStateNewContract({ signers: allSigners });
  };

  onClickRemoveDocumentSigner = (doc, signerId) => {
    this.addOrDeleteDocsSigner('delete', doc, signerId);
  };

  addSigner = () => {
    const { signers, allDocuments, signTypeSigner, multipleCheckboxSife } =
      this.state;
    const { updateStateNewContract } = this.props;
    const newSigner = {
      legalRfc: '',
      legalName: '',
      email: '',
      requestDocument: new Set(allDocuments),
      requestVerification: multipleCheckboxSife.globalRequestVerification,
      verifyCreateSign: multipleCheckboxSife.globalVerifyCreateSign,
      showButtons: signTypeSigner === '3' ? true : false,
      acceptedDisclaimer: !multipleCheckboxSife.globalVerifyCreateSign
        ? true
        : false,
    };
    this.setState({ signers: [...signers, newSigner] });
    updateStateNewContract({ signers: [...signers, newSigner] });
  };

  addMe = () => {
    const {
      signers,
      contractUser,
      allDocuments,
      signTypeSigner,
      multipleCheckboxSife,
    } = this.state;
    const { session, updateStateNewContract, translations, lang } = this.props;

    const { notifications } = translations[lang].NewContract.Signers;

    if (!signers.find((found) => found.legalRfc === contractUser.legalRfc)) {
      const newSigner = {
        legalName: contractUser.legalName,
        legalRfc: contractUser.legalRfc,
        email: session.user.secondaryEmail || session.user.email,
        requestDocument: new Set(allDocuments),
        requestVerification: multipleCheckboxSife.globalRequestVerification,
        verifyCreateSign: multipleCheckboxSife.globalVerifyCreateSign,
        showButtons: signTypeSigner === '3' ? true : false,
        acceptedDisclaimer: !multipleCheckboxSife.globalVerifyCreateSign
          ? true
          : false,
      };
      this.setState({ signers: [...signers, newSigner] });
      updateStateNewContract({ signers: [...signers, newSigner] });
    } else {
      NotificationManager.warning(
        notifications.notifBody1,
        notifications.notifTitle1,
        3000,
      );
    }
  };

  onChangeSetDocumentsAllSigners = (event, doc) => {
    const { checked } = event.target;
    let { signers, allDocuments } = this.state;
    const { updateStateNewContract } = this.props;
    const allSigners = [...signers];
    if (checked) {
      allDocuments.push(doc);
    } else {
      const filteresDocuments = allDocuments.filter(
        (document) => document !== doc,
      );
      allDocuments = filteresDocuments;
    }
    const allSignersUpdated = allSigners.map((signer) => {
      const signerUpdated = { ...signer };
      checked
        ? signerUpdated.requestDocument.add(doc)
        : signerUpdated.requestDocument.delete(doc);
      return signerUpdated;
    });
    this.setState({
      allDocuments,
      signers: allSignersUpdated,
    });
    updateStateNewContract({ signers: allSignersUpdated });
  };

  onInputDocumentChange = (event) => {
    const { signerDocument, modalDocumentType } = this.state;
    const { value } = event.target;
    if (event.keyCode === 13) {
      const signerId =
        modalDocumentType === 'allSigners' ? null : signerDocument.id;
      this.addOrDeleteDocsSigner('add', value, signerId);
    }
  };

  handleCancelPopUp = (signerId, type) => {
    const { signers } = this.state;
    const { updateStateNewContract } = this.props;
    const allSigners = [...signers];
    if (type === 'globalDisclaimer') {
      return this.setState({ showGlobalDisclaimer: false });
    }

    allSigners[signerId].showModal = false;
    allSigners[signerId].acceptedDisclaimer = false;
    this.setState({ signers: allSigners });
    updateStateNewContract({ signers: allSigners });
  };

  acceptDisclaimer = (signerId, type) => {
    const { signers, multipleCheckboxSife } = this.state;
    const { updateStateNewContract } = this.props;
    const allSigners = [...signers];
    const bothCheckbox = { ...multipleCheckboxSife };
    let updatedSigners = [];
    if (type === 'globalDisclaimer') {
      this.setState({ showGlobalDisclaimer: false });
      updatedSigners = allSigners.map((signer) => {
        return {
          ...signer,
          verifyCreateSign: false,
          acceptedDisclaimer: true,
        };
      });
      bothCheckbox.globalVerifyCreateSign = false;
      this.setState({
        signers: updatedSigners,
        multipleCheckboxSife: bothCheckbox,
      });
      return updateStateNewContract({ signers: updatedSigners });
    }
    allSigners[signerId].showModal = false;
    allSigners[signerId].acceptedDisclaimer = true;
    allSigners[signerId].verifyCreateSign = false;
    this.setState({ signers: allSigners });
    updateStateNewContract({ signers: allSigners });
  };

  //SE COMENTÓ PORQUE OPCIONES GLOBALES YA NO ESTÁ DISPONIBLE
  /*
  globalSignerCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const { multipleCheckboxSife, signers } = this.state;
    const { updateStateNewContract } = this.props;
    const bothCheckbox = { ...multipleCheckboxSife };
    const allSigners = [...signers];
    let updatedSigners = [];
    if (name === 'globalVerifyCreateSign') {
      if (!checked) {
        return this.setState({ showGlobalDisclaimer: true });
      }

      updatedSigners = allSigners.map((signer) => {
        return {
          ...signer,
          verifyCreateSign: checked,
        };
      });
      bothCheckbox[name] = checked;
    }

    if (name === 'globalRequestVerification') {
      bothCheckbox[name] = checked;
      updatedSigners = allSigners.map((signer) => {
        return {
          ...signer,
          requestVerification: checked,
        };
      });
    }

    this.setState({ multipleCheckboxSife: bothCheckbox });
    this.setState({ signers: updatedSigners });
    updateStateNewContract({ signers: updatedSigners });
  };
  */

  handleChangeCheckboxIdentityDocs = (showIdentityDocs) => {
    this.setState({ showIdentityDocs: !showIdentityDocs });
  };

  //BUSCAR DE CUALQUIER FORMA CUANDO SEA 12 O 13 INPUT CHANGE Y ONBLUR
  findUsersSife = async (e, signerId) => {
    const { value } = e.target;
    const { signers } = this.state;
    const { updateStateNewContract } = this.props;
    const allSigners = [...signers];
    if (value.length === 12 || value.length === 13) {
      const response = await ContractActions.getUserSife(value);
      allSigners[signerId].verifyCreateSign = response.allVerified;
      allSigners[signerId].hideButtonCreateSign = response.allVerified;
      this.setState({ signers: allSigners });
      updateStateNewContract({ signers: allSigners });
    }
  };

  onKeyUpFindUser = (e, index) => {
    const { value } = e.target;
    if (value.length === 12 || value.length === 13) {
      this.findUsersSife(e, index);
    }
  };

  generateIdSora = async (value, signerId) => {
    const { signers } = this.state;
    const { updateStateNewContract } = this.props;
    const allSigners = [...signers];
    const response = await ContractActions.generateSoraId({
      secondaryEmail: value,
    });
    const data = response?.data;

    allSigners[signerId].legalRfc = data?.success ? data?.rfc : '';
    allSigners[signerId].disableLegalRfc = !data.success;
    if (!data?.success && data?.type === 'MULTIPLE_USERS') {
      allSigners[signerId].disclaimerMultipleUsers = true;
      this.setState({ signers: allSigners });
      return updateStateNewContract({ signers: allSigners });
    }

    if (data?.success) {
      this.setState({ signers: allSigners });
      updateStateNewContract({ signers: allSigners });
      const eventTarget = {
        target: { value: data?.rfc },
      };
      this.findUsersSife(eventTarget, signerId);
    }
  };

  onBlurGenerateSoraIdToUsers = async (e, signerId, signType) => {
    const { value } = e.target;
    const { dropdownSigners } = this.state;

    if (value !== '' && signType === '4' && dropdownSigners) {
      await this.generateIdSora(value, signerId);
    }
  };

  handleCancelModalMultipleUsers = (signerId) => {
    const { signers } = this.state;
    const { updateStateNewContract } = this.props;
    const allSigners = [...signers];
    allSigners[signerId].disclaimerMultipleUsers = false;
    this.setState({ signers: allSigners });
    updateStateNewContract({ signers: allSigners });
  };

  showInputRfc = (signer) => {
    const { dropdownSigners } = this.state;
    return dropdownSigners && signer.signType === '4' && signer.disableLegalRfc;
  };

  showVerificationButtons = (signer) => {
    const { signTypeSigner } = this.state;

    return (
      signTypeSigner === '3' ||
      (signer.showButtons &&
        (signer.signType === '3' || signer.signType === '4'))
    );
  };

  onKeyUpSearchByEmail = async (e, signerId, signType) => {
    const { value } = e.target;
    const { dropdownSigners } = this.state;

    if (value.match(emailRegex) && signType === '4' && dropdownSigners) {
      await this.generateIdSora(value, signerId);
    }
  };

  showTooltip = (e, type) => {
    if (type === 'verification') {
      this.setState({ showTooltipReqVer: true });
    }
    const tooltip = document.getElementById('tooltip_modal');
    if (tooltip.style.display === 'block') {
      tooltip.style.display = 'none';
      this.setState({ showTooltipReqVer: false });
      return;
    }
    const clientX = e.clientX;
    const clientY = e.clientY;
    tooltip.style.display = 'block';
    tooltip.style.left = clientX + 'px';
    tooltip.style.top = clientY - (type === 'createSign' ? 160 : 130) + 'px';
  };

  render() {
    const {
      showTableSigners,
      showMessageSigner,
      signTypeSigner,
      orderedSigners,
      lastSigners,
      searchSigner,
      signers,
      dropdown,
      dropdownSigners,
      contractUser,
      documentModal,
      documentsList,
      signerDocument,
      modalDocumentType,
      allDocuments,
      showIdentityDocs,
      showGlobalDisclaimer,
      showTooltipReqVer,
      showTooltipReqCreateSign,
      modalWithOutRfc,
    } = this.state;

    const { translations, lang } = this.props;
    const SignersTranslation = translations[lang].NewContract.Signers;
    const { texts, buttons } = SignersTranslation;

    return (
      <>
        <div>
          <div className="dropdown_tables">
            <span style={{ color: 'black' }}>{texts.spanTitle}</span>
            {showTableSigners ? (
              <i
                className="fas fa-chevron-up"
                onClick={this.handleTableSigners}
              ></i>
            ) : (
              <i
                className="fas fa-chevron-down"
                onClick={this.handleTableSigners}
              ></i>
            )}
          </div>
          {showTableSigners ? (
            <>
              <ChooseSignatureType
                handleChangeTypeSign={this.handleChangeTypeSign}
                showMessageSigner={showMessageSigner}
                signTypeSigner={signTypeSigner}
              />
              {/* SE DEJA POR SI SE DESEA HACER GLOBAL */}
              {/* OPCIONES DE VERIFICACION DE IDENNTIDAD CON SIFE */}
              {/* {signTypeSigner === '3' ? (
                <>
                  <p>Opciones de verificación de identidad con SIFE</p>
                  <div className="tooltip top">
                    <span
                      className="tiptext"
                      style={{ width: 600, marginLeft: -200 }}
                    >
                      El firmante deberá comprobar con selfie e identificación
                      oficial que está firmando este contrato
                    </span>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 20,
                      }}
                    >
                      <input
                        style={{ transform: 'scale(1)', width: 30 }}
                        type="checkbox"
                        id="cbox2"
                        checked={multipleCheckboxSife.globalVerifyCreateSign}
                        name="globalVerifyCreateSign"
                        onChange={(e) => this.globalSignerCheckboxChange(e)}
                      />
                      <small style={{ paddingTop: 3 }}>
                        Los firmantes deben mantener firma ligada a identidad
                      </small>
                    </div>
                  </div>
                  <br />

                  <div className="tooltip top">
                    <span
                      className="tiptext"
                      style={{
                        width: 600,
                        marginLeft: -220,
                        marginBottom: -10,
                      }}
                    >
                      El firmante deberá comprobar con selfie e identificación
                      oficial que está firmando este contrato
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        style={{ transform: 'scale(1)', width: 30 }}
                        type="checkbox"
                        id="cbox2"
                        checked={multipleCheckboxSife.globalRequestVerification}
                        name="globalRequestVerification"
                        onChange={(e) => this.globalSignerCheckboxChange(e)}
                      />
                      <small style={{ paddingTop: 3 }}>
                        Los firmantes deben comprobar identidad al firmar este
                        contrato
                      </small>
                    </div>
                  </div>
                </>
              ) : null} */}
              {/* <div>
            <p
              style={{ cursor: 'pointer' }}
              onClick={() =>
                props.onClickOpenDocumentModal(null, null, 'allSigners')
              }
            >
              Solicitar mismos documentos a todos los firmantes{' '}
              <i
                style={{
                  color: '#0162E5',
                  fontSize: 18,
                  marginLeft: 10,
                }}
                className="far fa-id-badge"
              ></i>{' '}
            </p>
          </div> */}

              <ConfigSigners
                onChangeSearchSigner={this.onChangeSearchSigner}
                setWrapperRef={this.setWrapperRef}
                lastSigners={lastSigners}
                searchSigner={searchSigner}
                onClickSelectSigner={this.onClickSelectSigner}
                showIdentityDocs={showIdentityDocs}
                handleChangeCheckboxIdentityDocs={
                  this.handleChangeCheckboxIdentityDocs
                }
                orderedSigners={orderedSigners}
                handleChangeOrderSigners={this.handleChangeOrderSigners}
                translation={SignersTranslation}
              />

              <div className="signers_list">
                {signers.length ? (
                  signers.map((signer, index) => (
                    <div key={index} className="signer_key">
                      <SignersListNewContract
                        dropdownSigners={dropdownSigners}
                        index={index}
                        signerInputChange={this.signerInputChange}
                        signer={signer}
                        dropdown={dropdown}
                        onBlurGenerateSoraIdToUsers={
                          this.onBlurGenerateSoraIdToUsers
                        }
                        onKeyUpSearchByEmail={
                          this.debouncedOnKeyUpGenerateSoraId
                        }
                        showInputRfc={this.showInputRfc}
                        findUsersSife={this.findUsersSife}
                        onKeyUpFindUser={this.onKeyUpFindUser}
                        showVerificationButtons={this.showVerificationButtons}
                        signerCheckboxChange={this.signerCheckboxChange}
                        removeSigner={this.removeSigner}
                        orderedSigners={orderedSigners}
                        translation={SignersTranslation}
                      />

                      {showIdentityDocs ? (
                        <div className="add_document_nc">
                          <small
                            className="title_signers"
                            onClick={() =>
                              this.onClickOpenDocumentModal(signer, index, '')
                            }
                          >
                            {texts.link1}
                          </small>
                          <div className="user_documents">
                            {Array.from(signer.requestDocument).map(
                              (doc, i) => (
                                <span
                                  key={i}
                                  className="doc_nc"
                                  onClick={() =>
                                    this.onClickRemoveDocumentSigner(doc, index)
                                  }
                                >
                                  {doc} &#x2a2f;
                                </span>
                              ),
                            )}
                          </div>
                        </div>
                      ) : null}
                      <InfoSignersSife
                        showVerificationButtons={this.showVerificationButtons}
                        signer={signer}
                        signerCheckboxChange={this.signerCheckboxChange}
                        handleCancelPopUp={this.handleCancelPopUp}
                        acceptDisclaimer={this.acceptDisclaimer}
                        index={index}
                        handleCancelModalMultipleUsers={
                          this.handleCancelModalMultipleUsers
                        }
                        showTooltipReqVer={showTooltipReqVer}
                        showTooltipReqCreateSign={showTooltipReqCreateSign}
                        showTooltip={this.showTooltip}
                        translation={SignersTranslation}
                      />
                    </div>
                  ))
                ) : (
                  <p>{texts.p1}</p>
                )}
              </div>
              <div className="actions_signers">
                {!signers.find(
                  (found) => found.legalRfc === contractUser.legalRfc,
                ) ? (
                  <div className="action_signer">
                    <span onClick={this.addMe}>{buttons.button1}</span>
                  </div>
                ) : null}
                <div className="action_signer">
                  <span onClick={this.addSigner}>{buttons.button2}</span>
                </div>
              </div>
            </>
          ) : null}
        </div>

        <Tooltip
          title={showTooltipReqVer ? texts.tooltipTitle1 : texts.tooltipTitle2}
          info={texts.tooltipInfo1}
        />
        {documentModal ? (
          <ChooseDocumentModal
            handleCancel={this.onClickOpenDocumentModal}
            documentsList={documentsList}
            signerCheckboxChange={this.signerCheckboxChange}
            signerDocument={signerDocument}
            onChangeSetDocumentsAllSigners={this.onChangeSetDocumentsAllSigners}
            modalDocumentType={modalDocumentType}
            allDocuments={allDocuments}
            onInputDocumentChange={this.onInputDocumentChange}
            onClickRemoveDocumentSigner={this.onClickRemoveDocumentSigner}
          />
        ) : null}
        {showGlobalDisclaimer ? (
          <GenericConfirmModal
            title={texts.genericModalTitle2}
            message={texts.genericModalBody2}
            okButton={buttons.genericModalConfirmButton2}
            cancelButton={buttons.genericModalCancelButton2}
            cancelAction={() =>
              this.handleCancelPopUp(null, 'globalDisclaimer')
            }
            acceptAction={() => this.acceptDisclaimer(null, 'globalDisclaimer')}
          />
        ) : null}
        {modalWithOutRfc.open ? (
          <GenericConfirmModal
            title={texts.genericModalTitle}
            message={texts.genericModalBody}
            okButton={buttons.genericModalConfirmButton}
            cancelButton={buttons.genericModalCancelButton}
            cancelAction={() => this.closeModalWithOutRfc('cancel')}
            acceptAction={() => this.closeModalWithOutRfc('accept')}
          />
        ) : null}
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
    translations: ['translations'],
    lang: ['lang'],
  },
  SignersDisplay,
);
