import React from 'react';
import { branch } from 'baobab-react/higher-order';
import ProfileActions from '../../actions/Profile';
import {
  getRfcByEmail,
  usersMostUsedNewContract,
} from '../../utils/newContractMassiveUtils';
import { emailRegex } from '../../utils/constants';
import GenericConfirmModal from '../../components/Modals/GenericConfirmModal';
import { cleanText } from '../../utils/cleanText';

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

class RecipientsDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.debouncedOnKeyUpGenerateSoraId = debounce(
      this.onKeyUpGenerateSoraId.bind(this),
      500,
    );
    this.state = {
      showTableCopied: false,
      recipients: [],
      contractUser: '',
      searchRecipient: '',
    };
  }

  componentDidMount = async () => {
    const {
      session: { user },
      signer,
      admin,
    } = this.props;
    const contractUser = signer || admin || user;
    const id = contractUser.rootUser || contractUser.id;
    const recipientsArr = await ProfileActions.getLastSigners({
      userId: id,
      legalRfc: contractUser.legalRfc,
      typeUser: 'recipients',
      isAdmin: signer || admin ? 'isAdmin' : 'isUser',
    });

    this.setState({
      contractUser,
      lastRecipients: recipientsArr,
      allRecipients: recipientsArr,
    });
    document.addEventListener('mousedown', this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { searchRecipient, lastRecipients } = this.state;
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      searchRecipient &&
      lastRecipients.length
    ) {
      this.setState({ lastRecipients: [] });
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  onChangeSearchRecipients = (event) => {
    const { allRecipients } = this.state;
    const value = event.target.value;
    const lastChar = value[value.length - 1];
    if (lastChar === '\\' || lastChar === '?') return;
    this.setState({ searchRecipient: value });
    const limitedRecipients = usersMostUsedNewContract(value, allRecipients);
    this.setState({ lastRecipients: limitedRecipients });
  };

  onClickSelectRecipient = async (recipient) => {
    // const { recipients } = this.state;
    const { updateStateNewContract, recipients } = this.props;
    recipient.disableLegalRfc = true;
    this.setState({
      recipients: [...recipients, recipient],
      searchRecipient: '',
      lastRecipients: [],
    });
    updateStateNewContract({ recipients: [...recipients, recipient] });
  };

  handleTableCopied = () => {
    const { showTableCopied } = this.state;
    this.setState({ showTableCopied: !showTableCopied });
  };

  recipientInputChange = (recipientId, event) => {
    const { name, value } = event.target;
    // const { recipients } = this.state;
    const { updateStateNewContract, recipients } = this.props;
    const array = [...recipients];

    if (name === 'legalRfc') {
      if (value === ' ') return;
      array[recipientId][name] = cleanText({
        removeSpace: true,
        string: value,
      });
    } else if (name === 'email') {
      if (value === ' ') return;
      array[recipientId][name] = cleanText({
        removeSpace: true,
        string: value,
      });
      this.debouncedOnKeyUpGenerateSoraId(event, recipientId);
    } else {
      array[recipientId][name] = cleanText({
        removeSpace: false,
        string: value,
      });
    }
    this.setState({ recipients: array });
    updateStateNewContract({ recipients: array });
  };

  removeRecipient = (e) => {
    // const { recipients } = this.state;
    const { updateStateNewContract, recipients } = this.props;
    const array = [...recipients];
    const filteredArray = array.filter((recipient) => recipient !== e);
    this.setState({ recipients: filteredArray });
    updateStateNewContract({ recipients: filteredArray });
  };

  addRecipient = () => {
    // const { recipients } = this.state;
    const { updateStateNewContract, recipients } = this.props;
    const newRecipient = {
      legalRfc: '',
      legalName: '',
      email: '',
      disableLegalRfc: true,
    };
    this.setState({ recipients: [...recipients, newRecipient] });
    updateStateNewContract({ recipients: [...recipients, newRecipient] });
  };

  onKeyUpGenerateSoraId = async (e, recipientId) => {
    const { value } = e.target;
    const newValue = cleanText({
      removeSpace: true,
      string: value,
    });

    // const { recipients } = this.state;
    const { updateStateNewContract, recipients } = this.props;
    const allRecipients = [...recipients];
    const data = {
      allUsers: allRecipients,
      participantId: recipientId,
      participant: '',
      value: newValue,
      typeOfParticipant: 'recipient',
    };
    if (newValue.match(emailRegex)) {
      // setTimeout(async () => {
      //   const allUsers = await getRfcByEmail(data);
      //   this.setState({ recipients: allUsers });
      //   return updateStateNewContract({ recipients: allUsers });
      // }, 1000);
      const allUsers = await getRfcByEmail(data);
      this.setState({ recipients: allUsers });
      return updateStateNewContract({ recipients: allUsers });
    }
  };

  onClickEnable = (recipientId, type) => {
    // const { recipients } = this.state;
    const { updateStateNewContract, recipients } = this.props;
    const allRecipients = [...recipients];
    if (type === 'closeDisclaimer') {
      allRecipients[recipientId].disclaimerMultipleUsers = false;
    }

    allRecipients[recipientId].disableLegalRfc = false;
    allRecipients[recipientId].legalRfc = '';
    this.setState({ recipients: allRecipients });
    updateStateNewContract({ recipients: allRecipients });
  };

  render() {
    const { showTableCopied, lastRecipients, searchRecipient } = this.state;
    const { recipients, translations, lang } = this.props;
    const { texts, inputs, buttons } =
      translations[lang].NewContract.Recipients;

    return (
      <>
        <div className="dropdown_tables" style={{ marginTop: 20 }}>
          <span style={{ color: 'black' }}>{texts.spanTitle}</span>
          {showTableCopied ? (
            <i
              className="fas fa-chevron-up"
              onClick={this.handleTableCopied}
            ></i>
          ) : (
            <i
              className="fas fa-chevron-down"
              onClick={this.handleTableCopied}
            ></i>
          )}
        </div>
        {showTableCopied ? (
          <>
            <div className="container_config_recipients">
              <div className="container_search_recipient">
                <div className="input_search_signer">
                  <i
                    className="fas fa-search iconoInput"
                    style={{ color: '#6C6C6C' }}
                  />
                  <input
                    id="myRecipients"
                    name="myRecipients"
                    placeholder={inputs.inputPlaceholder1}
                    onChange={(e) => this.onChangeSearchRecipients(e)}
                    value={searchRecipient}
                    type="text"
                    autoComplete="off"
                    className="input_nc search_signer"
                  />
                </div>
                <div className="dropdown">
                  <div
                    ref={this.setWrapperRef}
                    className="dropdown-content"
                    style={{
                      display:
                        lastRecipients?.length && searchRecipient
                          ? 'block'
                          : 'none',
                    }}
                  >
                    {lastRecipients?.map((recipient, i) => (
                      <div
                        onClick={() => this.onClickSelectRecipient(recipient)}
                        key={i}
                      >
                        <p style={{ marginBottom: 4, marginTop: 0 }}>
                          {recipient.legalName}{' '}
                        </p>
                        <small>
                          {recipient.legalRfc} /{' '}
                          {recipient.secondaryEmail || recipient.email}{' '}
                        </small>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="list_recipients_nc">
              {recipients.length ? (
                recipients.map((recipient, index) => (
                  <div key={index} className="container_inputs_recipients">
                    <div className="inputs_recipients">
                      <div className="legalRfc_recipient_nc">
                        <small>{index + 1}.</small>
                        <input
                          type="email"
                          name="email"
                          autoComplete="nope"
                          value={recipient.email}
                          className="input_nc email_recipient_nc"
                          onChange={(e) => this.recipientInputChange(index, e)}
                          required
                          placeholder={inputs.inputPlaceholder2}
                          // onKeyUp={(e) => this.onKeyUpGenerateSoraId(e, index)}
                        />
                      </div>
                      <input
                        type="text"
                        minLength="10"
                        name="legalName"
                        value={recipient.legalName}
                        className="input_nc legalName_recipient_nc"
                        required
                        onChange={(e) => this.recipientInputChange(index, e)}
                        placeholder={inputs.inputPlaceholder3}
                      />
                      <input
                        type="text"
                        name="legalRfc"
                        value={recipient.legalRfc}
                        style={{
                          textTransform: 'uppercase',
                          opacity: recipient.disableLegalRfc ? '0.5' : '1',
                          border: recipient.disableLegalRfc
                            ? '2px solid #e5e8ef'
                            : '1px solid #e5e8ef',
                        }}
                        disabled={recipient.disableLegalRfc}
                        className="input_nc"
                        required
                        minLength="12"
                        maxLength="13"
                        onChange={(e) => this.recipientInputChange(index, e)}
                        placeholder={inputs.inputPlaceholder4}
                      />
                    </div>
                    <div className="action_delete_recipient_nc">
                      <small>{index + 1}.</small>
                      <i
                        className={`fas fa-minus-circle`}
                        style={{
                          color: '#f95c5e',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.removeRecipient(recipient)}
                      />
                    </div>
                    {recipient.disclaimerMultipleUsers ? (
                      <GenericConfirmModal
                        title={texts.genericModalTitle1}
                        message={texts.genericModalBody1}
                        type="hideButton"
                        cancelButton={buttons.genericModalCancelButton1}
                        cancelAction={() =>
                          this.onClickEnable(index, 'closeDisclaimer')
                        }
                      />
                    ) : null}
                  </div>
                ))
              ) : (
                <p>{texts.p1}</p>
              )}
            </div>
            <div className="actions_signers">
              <div className="action_signer">
                <span onClick={this.addRecipient}>{buttons.button1}</span>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }
}

export default branch(
  {
    session: ['session'],
    translations: ['translations'],
    lang: ['lang'],
  },
  RecipientsDisplay,
);
